/* eslint-disable no-irregular-whitespace */
/* eslint-disable react/no-unknown-property */
import Product from './components/Product';
import Latex from './components/Latex';
import React from 'react';

let clock_icon = (
  <svg xmlns='http://www.w3.org/2000/svg' height='64' width='64' viewBox='0 0 7 8' fill='#F44336'>
    \n{' '}
    <path d='M2 0v1h1v.03c-1.7.24-3 1.71-3 3.47 0 1.93 1.57 3.5 3.5 3.5s3.5-1.57 3.5-3.5c0-.45-.1-.87-.25-1.25l-.91.38c.11.29.16.57.16.88 0 1.39-1.11 2.5-2.5 2.5s-2.5-1.11-2.5-2.5 1.11-2.5 2.5-2.5c.3 0 .59.05.88.16l.34-.94c-.23-.08-.47-.12-.72-.16v-.06h1v-1h-3zm5 1.16s-3.65 2.81-3.84 3c-.19.2-.19.49 0 .69.19.2.49.2.69 0 .2-.2 3.16-3.69 3.16-3.69z'></path>
  </svg>
);
//let beaker_icon = <svg xmlns="http://www.w3.org/2000/svg" fill="#4FC3F7" width="64" height="64" viewBox="0 0 8 8"><path d="M1.34 0a.5.5 0 0 0 .16 1h.5v1.41c-.09.17-1.2 2.31-1.66 3.09-.16.26-.34.61-.34 1.06 0 .39.15.77.41 1.03s.64.41 1.03.41h5.13c.38 0 .74-.16 1-.41h.03c.26-.26.41-.64.41-1.03 0-.45-.19-.8-.34-1.06-.46-.78-1.57-2.92-1.66-3.09v-1.41h.5a.5.5 0 1 0 0-1h-5a.5.5 0 0 0-.09 0 .5.5 0 0 0-.06 0zm1.66 1h2v1.63l.06.09s.63 1.23 1.19 2.28h-4.5c.56-1.05 1.19-2.28 1.19-2.28l.06-.09v-1.63z\"></path></svg>;
let graph_icon = (
  <svg xmlns='http://www.w3.org/2000/svg' width='64' height='64' fill='#81C784' viewBox='0 0 8 8'>
    <path d='M7.03 0l-3.03 3-1-1-3 3.03 1 1 2-2.03 1 1 4-4-.97-1zm-7.03 7v1h8v-1h-8z'></path>
  </svg>
);
let no_icon = (
  <img height='64px' src='https://img.icons8.com/material-outlined/96/000000/cancel-2.png' />
);
let reduce_sendouts_icon = (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='64'
    height='64'
    viewBox='0 0 307.11 307.11'
    fill='#F57F17'
  >
    <title>Reduce Sendouts</title>
    <path
      className='circle'
      d='M37.75,83.38V192.44h65.8V83.38Zm27.11,98H50.33V166.83H64.86Zm0-23.81H50.33V143H64.86Zm0-23.83H50.33V119.19H64.86Zm0-23.83H50.33V95.38H64.86Zm27,71.47H77.37V166.83H91.88Zm0-23.81H77.37V143H91.88Zm0-23.83H77.37V119.19H91.88Zm0-23.83H77.37V95.38H91.88Z'
    />
    <path
      className='circle'
      d='M210.26,113.09V202H276v-88.9Zm38.9,10.77h14.51v14.51H249.16Zm0,23.81h14.51V162.2H249.16Zm0,23.83h14.51V186H249.16Zm-27-47.64h14.53v14.51H222.12Zm0,23.81h14.53V162.2H222.12Zm0,23.83h14.53V186H222.12Z'
    />
    <rect className='circle' x='202.36' y='192.45' width='81.08' height='9.54' />
    <rect className='circle' x='30.11' y='192.45' width='81.08' height='9.54' />
    <rect className='circle' x='27.39' y='209.64' width='256.05' height='17.47' />
    <line
      className='b'
      x1='118.02'
      y1='149.28'
      x2='181.99'
      y2='149.28'
      stroke='#F57F17'
      strokeWidth={'20px'}
    />
    <polygon
      className='circle'
      points='153.18 184.84 147.24 178.47 178.65 149.28 147.24 120.09 153.18 113.72 191.42 149.28 153.18 184.84'
    />
    <path
      className='circle'
      d='M203.26,8.23a153.59,153.59,0,1,0-51.7,298.86C236.22,308.2,306,240.22,307.1,155.55A153.23,153.23,0,0,0,203.26,8.23ZM151.73,293.44A139.92,139.92,0,1,1,293.44,155.37C292.43,232.51,228.87,294.44,151.73,293.44Z'
    />
    <line
      className='c'
      x1='7.21'
      y1='18.9'
      x2='300.61'
      y2='287.43'
      stroke='#F57F17'
      strokeWidth={'25px'}
    />
  </svg>
);
let run_all_shifts_icon = (
  <svg xmlns='http://www.w3.org/2000/svg' height='64' width='64' viewBox='0 0 203.26 126.47'>
    <title>Run On All Shifts</title>
    <path
      style={{ fill: '#4CAF50' }}
      d='M189.11,0h-175A14.13,14.13,0,0,0,0,14.14v98.18a14.12,14.12,0,0,0,14.12,14.15h175a14.14,14.14,0,0,0,14.15-14.15V14.14A14.15,14.15,0,0,0,189.11,0ZM49.94,35.69A13.69,13.69,0,1,1,36.26,49.37,13.69,13.69,0,0,1,49.94,35.69Zm21.42,71.23H28.51V90.79a21.43,21.43,0,1,1,42.85,0Zm64,0H93.24v-5.45H135.4Zm42.15-17.76H93.24V83.71h84.31Zm0-17.78H93.24V65.94h84.31Zm0-20.92H93.24V33h84.31Z\'
    />
  </svg>
);
let save_money = (
  <svg xmlns='http://www.w3.org/2000/svg' width='64' height='64' viewBox='0 0 182.89 182.88'>
    <title>Save Money</title>
    <path
      className='money'
      fill='#4CAF50'
      d='M85.32,138.38h0a5.89,5.89,0,0,1-4-5.66l.06-4.86a32.87,32.87,0,0,1-12.92-5.16,5.92,5.92,0,0,1-2-6.81L69,109.15a5.9,5.9,0,0,1,8.86-2.8A21.6,21.6,0,0,0,89,109.85a8,8,0,0,0,4.73-1.21,3.28,3.28,0,0,0,1.45-2.71c0-1.28,0-3.42-7.65-6.66-9.41-3.83-20.22-9.94-20-23.2.13-9.69,6-17.46,15.52-20.64l.08-5.63a5.91,5.91,0,0,1,6-5.82l6.26.08a6,6,0,0,1,1.83.32,5.89,5.89,0,0,1,4,5.65l-.06,4.51a31,31,0,0,1,10.17,3.88,5.88,5.88,0,0,1,2.44,7.2l-2.61,6.64a5.91,5.91,0,0,1-8.48,2.92,18.67,18.67,0,0,0-9.77-2.79c-3.1,0-4.82.93-4.84,2.73,0,1.07,0,2.39,8.8,6.18,8.23,3.49,19.17,9.79,19,24.19-.13,9.92-6.45,18.21-16.25,21.52L99.49,133a5.92,5.92,0,0,1-6,5.83l-6.37-.09A6,6,0,0,1,85.32,138.38Zm-1-14.51a2.22,2.22,0,0,1,1.5,2.13l-.09,6.78a1.47,1.47,0,0,0,1,1.4h0a1.44,1.44,0,0,0,.46.08l6.36.08a1.46,1.46,0,0,0,1.48-1.44l.1-7.57a2.21,2.21,0,0,1,1.64-2.11c8.88-2.42,14.47-9.23,14.58-17.79.15-11.53-8.15-16.61-16.29-20.05s-11.56-5.74-11.5-10.32c0-3.33,2.52-7.19,9.34-7.1a23.48,23.48,0,0,1,7.39,1.25,23.82,23.82,0,0,1,4.57,2.15,1.47,1.47,0,0,0,1.21.12,1.44,1.44,0,0,0,.89-.85L109.59,64a1.45,1.45,0,0,0-.6-1.79A27.13,27.13,0,0,0,98.56,58.6a2.21,2.21,0,0,1-1.9-2.22l.08-6.4a1.46,1.46,0,0,0-1.45-1.48L89,48.41a1.49,1.49,0,0,0-1,.42,1.43,1.43,0,0,0-.44,1l-.09,7.25a2.23,2.23,0,0,1-1.64,2.11C77.24,61.53,72.06,67.85,72,76.12c-.13,10.5,8.24,15.36,17.31,19,7.45,3.14,10.45,6.27,10.39,10.82a7.68,7.68,0,0,1-3.27,6.23,12.18,12.18,0,0,1-7.4,2.06A26,26,0,0,1,75.3,110a1.42,1.42,0,0,0-1.26-.19,1.47,1.47,0,0,0-.94.88l-2.51,6.74a1.46,1.46,0,0,0,.51,1.69c3,2.19,7.94,4,12.83,4.65A2.59,2.59,0,0,1,84.35,123.87Z\'
    />
    <path
      className='money'
      d='M61.84,178a91.51,91.51,0,1,1,28.41,4.89A91.25,91.25,0,0,1,61.84,178Zm56.34-164.7A82.59,82.59,0,1,0,174,92.52,82.57,82.57,0,0,0,118.18,13.29Z'
    />
    <path
      className='money'
      d='M68.35,158.94a71.4,71.4,0,1,1,22.17,3.82A71.13,71.13,0,0,1,68.35,158.94ZM113.1,28.14a66.9,66.9,0,1,0,45.23,64.17A66.8,66.8,0,0,0,113.1,28.14Z'
    />
  </svg>
);
let accuracy_icon = (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='64'
    height='64'
    fill='#039BE5'
    viewBox='0 0 224.65 186.01'
  >
    <title>Accuracy</title>
    <polygon
      className='target'
      points='213.31 93.01 224.65 103.79 207.01 103.79 199.81 96.61 91.12 96.61 91.12 89.42 199.81 89.42 207.01 82.22 224.65 82.22 213.31 93.01'
    />
    <path
      className='target'
      d='M104.13,103.79a15.51,15.51,0,1,1,0-21.57h16.18a29.36,29.36,0,1,0,0,21.57Zm81.78-14.37H91.12v7.19h94.79c.06-1.19.08-2.41.08-3.62S186,90.61,185.91,89.42ZM93,168.66a75.66,75.66,0,1,1,74.88-86.44h17.49a93,93,0,1,0,0,21.57H167.87A75.67,75.67,0,0,1,93,168.66Zm44.9-86.44h13.23a59.15,59.15,0,1,0,0,21.57H137.89a46.16,46.16,0,1,1,0-21.57ZM93,77.48a15.4,15.4,0,0,1,11.16,4.74h16.18a29.36,29.36,0,1,0,0,21.57H104.13A15.52,15.52,0,1,1,93,77.48Zm11.14,26.31a15.51,15.51,0,1,1,0-21.57h16.18a29.36,29.36,0,1,0,0,21.57ZM93,139.18a46.17,46.17,0,1,1,44.9-57h13.23a59.15,59.15,0,1,0,0,21.57H137.89A46.21,46.21,0,0,1,93,139.18Zm0-61.7a15.4,15.4,0,0,1,11.16,4.74h16.18a29.36,29.36,0,1,0,0,21.57H104.13A15.52,15.52,0,1,1,93,77.48Z'
    />
  </svg>
);

export function getASP() {
  return (
    <Product
      t_color='table-info'
      title_1='sōna'
      title_2='ASPERGILLUS GM'
      title_2_color='#5f285e'
      title_3='LATERAL FLOW ASSAY'
      etq={['PIS-00226','PIS-00235','PIS-00236','PIS-00262']}
      product_image='/images/immy/AF2003.jpg'
      product_desc={
        <div>
          <p>
            Over 30 million people are at risk of <a>invasive aspergillosis</a> each year due to use
            of corticosteroids, chemotherapy or other immunosuppressive agents, and over 300,000
            patients develop it annually. <sup>1</sup>
          </p>
          <p>
            Timely diagnosis and initiation of therapy strongly influence outcome. IMMY’s lateral
            flow assay can detect <i>Aspergillus</i> biomarkers in under an hour.
          </p>
          <p>
            <small>
              1) Invasive Aspergillosis. Leading International Fungal Education.{' '}
              <a target='_blank' rel="noreferrer" href='https://en.fungaleducation.org/invasive-aspergillosis-mucormycosis/'>
                {' '}
                https://en.fungaleducation.org/invasive-aspergillosis-mucormycosis/
              </a>
            </small>
          </p>
        </div>
      }
      CE_desc=': Serum & BAL'
      image_1={clock_icon}
      image_1_desc1='Results in just'
      image_1_desc2='45 MINUTES'
      image_2={accuracy_icon}
      image_2_desc1='Highly'
      image_2_desc2='SENSITIVE & SPECIFIC'
      image_3={graph_icon}
      image_3_desc1='Test'
      image_3_desc2='MORE OFTEN'
      relevancy='Asp_Relevancy'
      CE_marked={true}
      documentCode={'asp'}
      ordering={[
        {
          name: '<i>Aspergillus</i> Galactomannan LFA',
          quantity: '50 tests',
          code: 'AF2003',
        },
      ]}
      studies={[
        /*{
                "title":"Fungal Infections. Leading International Fungal Education",
                  "link":"http://www.life-worldwide.org/fungal-diseases"
            }*/
        /*{
                    "link": "https://onlinelibrary.wiley.com/doi/10.1111/myc.12881",
                    "title": "Point-of-care diagnosis of invasive aspergillosis in non-neutropenic patients: Aspergillus Galactomannan Lateral Flow Assay versus Aspergillus-specific Lateral Flow Device test in bronchoalveolar lavage.",
                    "citation": "Jenks JD, Mehta SR, Taplitz R, Aslam S, Reed SL, Hoenigl M. Point-of-care diagnosis of invasive aspergillosis in non-neutropenic patients: Aspergillus Galactomannan Lateral Flow Assay versus Aspergillus-specific Lateral Flow Device test in bronchoalveolar lavage. Mycoses. 2019 Mar;62(3):230-236."
                },*/ {
          link: 'https://www.journalofinfection.com/article/S0163-4453(18)30319-0/abstract',
          title:
            'Bronchoalveolar lavage Aspergillus Galactomannan lateral flow assay versus Aspergillus-specific lateral flow device test for diagnosis of invasive pulmonary Aspergillosis in patients with hematological malignancies',
          citation:
            'Jenks JD, Mehta SR, Taplitz R, Law N, Reed SL, Hoenigl M. Bronchoalveolar lavage Aspergillus Galactomannan lateral flow assay versus Aspergillus-specific lateral flow device test for diagnosis of invasive pulmonary Aspergillosis in patients with hematological malignancies. J Infect. 2019 Mar;78(3):249-259.',
        },
        {
          link: 'https://www.clinicalmicrobiologyandinfection.com/article/S1198-743X(19)30450-1/fulltext',
          title:
            'Respiratory specimens and the diagnostic accuracy of Aspergillus lateral flow assays (LFA-IMMY™): real-life data from a multicentre study.',
          citation:
            'Lass-Flörl C, Lo Cascio G, Nucci M, Camargo Dos Santos M, Colombo AL, Vossen M, Willinger B. Respiratory specimens and the diagnostic accuracy of Aspergillus lateral flow assays (LFA-IMMY™): real-life data from a multicentre study. Clin Microbiol Infect. 2019 Dec;25(12):1563.e1-1563.e3.',
        },
        {
          title:
            'Lateral flow assays for diagnosing invasive pulmonary aspergillosis in adult hematology patients: A comparative multicenter study',
          citation:
            'Mercier T, Dunbar A, de Kort E, et al. Lateral flow assays for diagnosing invasive pulmonary aspergillosis in adult hematology patients: A comparative multicenter study. Med Mycol. 2020;58(4):444-452.',
          link: 'https://pubmed.ncbi.nlm.nih.gov/31290552/',
        },
        {
          title:
            'The Aspergillus Lateral Flow Assay for the diagnosis of Invasive Aspergillosis: an Update',
          citation:
            'Jenks JD, Miceli MH, Prattes J, Mercier T, Hoenigl M. The Aspergillus Lateral Flow Assay for the Diagnosis of Invasive Aspergillosis: an Update [published online ahead of print, 2020 Dec 4]. Curr Fungal Infect Rep. 2020;1-6.',
          link: 'https://www.ncbi.nlm.nih.gov/pmc/articles/PMC7717101/',
        },
        /*  {
                    title:"Point of Care Aspergillus Testing in Intensive Care Patients ",
                    citation:"Mercier T, et al. Point of care aspergillus testing in intensive care patients. Crit Care. 2020.",
                    link:"https://pubmed.ncbi.nlm.nih.gov/33168049/"
                },*/
        {
          title:
            'Evaluation of the Performance of the IMMY sōna Aspergillus Galactomannan Lateral Flow Assay When Testing Serum to Aid in Diagnosis of Invasive Aspergillosis',
          citation:
            'White PL, Price JS, Posso R, Cutlan-Vaughan M, Vale L, Backx M. Evaluation of the Performance of the IMMY sona Aspergillus Galactomannan Lateral Flow Assay When Testing Serum to Aid in Diagnosis of Invasive Aspergillosis. J Clin Microbiol. 2020;58(6):e00053-20.',
          link: 'https://pubmed.ncbi.nlm.nih.gov/32188687/',
        },
        {
          title:
            'Accuracy of galactomannan testing on tracheal aspirates in COVID-19-associated pulmonary aspergillosis',
          citation:
            'Roman-Montes ACM, Martinez-Gamboa A, Diaz-Lomelí P, et al. Accuracy of galactomannan testing on tracheal aspirates in COVID-19-associated pulmonary aspergillosis [published online ahead of print, 2020 Nov 20]. Mycoses. 2020;10.1111/myc.13216.',
          link: 'https://pubmed.ncbi.nlm.nih.gov/33217784/',
        },
      ]}
    />
  );
}

export function getCrag() {
  return (
    <Product
      etqfix={true} // remove this please pretty please
      title_override={true}
      prod_title={
        <div className='my-auto col-12 text-center'>
          <h1 style={{ color: '#c41230' }} className='d-inline display-4 verlag-black '>
            CrAg®
          </h1>{' '}
          <h1 style={{ display: 'inline', color: '#00539b' }} className='display-4 verlag-light'>
            LFA
          </h1>
          <h2 className='text-center verlag-light' style={{ color: '#1565C0' }}>
            {' '}
            <i>CRYPTOCOCCAL</i> ANTIGEN
          </h2>
        </div>
      }
      title_2={
        <div>
          CrAg® <span style='color: #1565C0'>LFA</span>
        </div>
      }
      title_2_color='#c41230'
      title_3='CRYPTOCOCCAL ANTIGEN'
      etq={['PIS-00083','PIS-00068','PIS-00084','PIS-00107','PIS-00108']}
      product_image='/images/immy/CR2003.jpg'
      product_desc={
        <div>
          <p>
            <a>Cryptococcal meningitis </a> is one of the most common opportunistic infections among
            HIV/AIDS patients, estimating more than 220,000 cases and 181,000 deaths worldwide each
            year.<sup>1</sup> Early detection and treatment of infection are key to reducing the
            mortality associated with this disease.
          </p>
          <p>
            {' '}
            The CrAg LFA is highly sensitive and specific, with a rapid turnaround time of just 10
            minutes.
          </p>
          <p>
            <small>
              1) Rajasingham R, Smith RM, Park BJ, et al. Global burden of disease of HIV-associated
              cryptococcal meningitis: an updated analysis. Lancet Infect Dis. 2017;17(8):873-881.
            </small>
          </p>
        </div>
      }
      CE_desc={
        <>
          : Whole Blood, Serum, Plasma & CSF <br />
          FDA : Serum, CSF
        </>
      }
      image_1={clock_icon}
      image_1_desc1='Results in just'
      image_1_desc2='10 MINUTES'
      image_2={accuracy_icon}
      image_2_desc1='Highly'
      image_2_desc2='SENSITIVE & SPECIFIC'
      image_3={no_icon}
      image_3_desc1='No'
      image_3_desc2={'Pretreatment Required'.toUpperCase()}
      relevancy='Crag_Relevancy'
      CE_marked={true}
      studies={[
        {
          link: 'https://jcm.asm.org/content/57/1/e01238-18',
          title:
            'Cryptococcal Meningitis Diagnostics and Screening in the Era of Point-of-Care Laboratory Testing',
          citation:
            'Rajasingham R, Wake RM, Beyene T, Katende A, Letang E, Boulware DR. Cryptococcal Meningitis Diagnostics and Screening in the Era of Point-of-Care Laboratory Testing.' +
            ' J Clin Microbiol. 2019 Jan 2;57(1).',
        },
        {
          link: 'https://academic.oup.com/cid/article/58/1/113/373307',
          title:
            'Point-of-Care Diagnosis and Prognostication of Cryptococcal Meningitis With the Cryptococcal Antigen Lateral Flow Assay on Cerebrospinal Fluid',
          citation:
            'Kabanda T, Siedner MJ, Klausner JD, Muzoora C, Boulware DR. Point-of-care diagnosis and prognostication of cryptococcal meningitis with the cryptococcal antigen lateral flow assay on cerebrospinal fluid. Clin Infect Dis. 2014;58(1):113–116. ',
        },
        {
          link: 'https://www.sciencedirect.com/science/article/abs/pii/S0732889315000280',
          title:
            'Large-scale clinical validation of a lateral flow immunoassay for detection of cryptococcal antigen in serum and cerebrospinal fluid specimens',
          citation:
            'Suwantarat N, Dalton JB, Lee R, Green R, Memon W, Carroll KC, Riedel S, Zhang SX. Large-scale clinical validation of a lateral flow immunoassay for detection of cryptococcal antigen in serum and cerebrospinal fluid specimens. Diagn Microbiol Infect Dis. 2015 May;82(1):54-6.',
        },
        {
          link: 'https://www.ncbi.nlm.nih.gov/pubmed/23480688',
          title: 'CrAg lateral flow assay for cryptococcosis.',
          citation:
            'Kozel TR, Bauman SK. CrAg lateral flow assay for cryptococcosis. Expert Opin Med Diagn. 2012;6(3):245–251. ',
        },
        {
          link: 'https://jcm.asm.org/content/54/2/460.short',
          title:
            'Performance of the Cryptococcal Antigen Lateral Flow Assay in Non-HIV-Related Cryptococcosis',
          citation:
            'Jitmuang A, Panackal AA, Williamson PR, Bennett JE, Dekker JP, Zelazny AM. Performance of the Cryptococcal Antigen Lateral Flow Assay in Non-HIV-Related Cryptococcosis. J Clin Microbiol. 2016;54(2):460–463.  ',
        },
      ]}
      documentCode='cr2003'
      ordering={[
        {
          name: '<i>Cryptococcus</i> Antigen LFA',
          quantity: '50 tests',
          code: 'CR2003',
        },
      ]}
    />
  );
}

export function getCragSQ() {
  return (
    <Product
      title_override={true}
      prod_title={
        <div className=' my-auto  col-12 text-center'>
          <h1 style={{ color: '#c41230' }} className='d-inline display-4 verlag-black '>
            CrAg®
          </h1>{' '}
          <h1 style={{ display: 'inline', color: '#c41230' }} className='display-4 verlag-light'>
            SQ
          </h1>
          <h2 className='text-center verlag-light' style={{ color: '#666666' }}>
            {' '}
            SEMI-QUANTITATIVE LATERAL FLOW ASSAY
          </h2>
          <h2 className='text-center verlag-black' style={{ color: '#c41230' }}>
            {' '}
            CRYPTOCOCCAL ANTIGEN
          </h2>
        </div>
      }
      title_2={
        <div>
          CrAg® <span style={{ color: '#1565C0' }}>SQ</span>
        </div>
      }
      title_2_color='#c41230'
      title_3='CRYPTOCOCCAL ANTIGEN'
      etq={[]}
      product_image='/images/cragsq_product.jpg'
      product_desc={
        <div>
          <p>
            <a>Cryptococcal meningitis </a> is one of the most common opportunistic infections among
            HIV/AIDS patients, estimating more than 220,000 cases and 181,000 deaths worldwide each
            year.<sup>1</sup> Early detection and treatment of infection are key to reducing the
            mortality associated with this disease.
          </p>
          <p>
            {' '}
            CrAgSQ provides a semi-quantitative result in a single step test, which provides
            immediate risk stratification for patients cryptococcal meningitis.<sup>2</sup>
            <p>
              <small>
                1) Rajasingham R, Smith RM, Park BJ, et al. Global burden of disease of
                HIV-associated cryptococcal meningitis: an updated analysis. Lancet Infect Dis.
                2017;17(8):873-881.
              </small>
            </p>
          </p>
        </div>
      }
      CE_desc=': Serum, Plasma, & CSF'
      image_1={clock_icon}
      image_1_desc1='Results in just'
      image_1_desc2='10 MINUTES'
      image_2={accuracy_icon}
      image_2_desc1='Highly'
      image_2_desc2='SENSITIVE & SPECIFIC'
      image_3={no_icon}
      image_3_desc1='No'
      image_3_desc2={'Pretreatment Required'.toUpperCase()}
      relevancy='CragSQ_Relevancy'
      CE_marked={true}
      studies={[
        {
          link: 'https://doi.org/10.1128/jcm.00860-21',
          title:
            ' Evaluation of the Diagnostic Performance of a Semiquantitative Cryptococcal Antigen Point-of-Care Assay among HIV-Infected Persons with Cryptococcal Meningitis',
          citation:
            'Tadeo KK, Nimwesiga A, Kwizera R, et al. Evaluation of the Diagnostic Performance of a Semiquantitative Cryptococcal Antigen Point-of-Care Assay among HIV-Infected Persons with Cryptococcal Meningitis. J Clin Microbiol. 2021;59(8):e0086021',
        },
        {
          link: 'https://doi.org/10.1128/jcm.00441-20',
          title:
            'Evaluation of a Novel Semiquantitative Cryptococcal Antigen Lateral Flow Assay in Patients with Advanced HIV Disease',
          citation:
            ' Jarvis JN, Tenforde MW, Lechiile K, et al. Evaluation of a Novel Semiquantitative Cryptococcal Antigen Lateral Flow Assay in Patients with Advanced HIV Disease. J Clin Microbiol. 2020;58(9):e00441-20.',
        },
        {
          link: 'https://doi.org/10.1128/jcm.02046-19',
          title:
            'Evaluation of Serum Cryptococcal Antigen Testing Using Two Novel Semiquantitative Lateral Flow Assays in Persons with Cryptococcal Antigenemia',
          citation:
            'Skipper C, Tadeo K, Martyn E, et al. Evaluation of Serum Cryptococcal Antigen Testing Using Two Novel Semiquantitative Lateral Flow Assays in Persons with Cryptococcal Antigenemia. J Clin Microbiol. 2020;58(4):e02046-19',
        },
        {
          link: 'https://doi.org/10.1093/mmy/myab038',
          title:
            'Association of semi-quantitative cryptococcal antigen results in plasma with subclinical cryptococcal meningitis and mortality among patients with advanced HIV disease',
          citation:
            'Blasich NP, Wake RM, Rukasha I, Prince Y, Govender NP. Association of semi-quantitative cryptococcal antigen results in plasma with subclinical cryptococcal meningitis and mortality among patients with advanced HIV disease. Med Mycol. 2021;59(10):1041-1047 ',
        },
      ]}
      documentCode='CrAgSQ'
      ordering={[
        {
          name: 'Cryptococcus Antigen Semi-Quantitative LFA',
          quantity: '50 tests',
          code: 'CRAGSQ',
        },
      ]}
    />
  );
}

export function getCTA() {
  return (
    <Product
      title_1='sōna'
      title_2='COCCIDIOIDES ANTIBODY'
      title_2_color='#ff7e2e'
      title_3='LATERAL FLOW ASSAY'
      etq={['PIS-00206','PIS-00228','PIS-00234']}
      product_image='/images/immy/CTA2003-3d.jpg'
      product_desc={
        <div>
          <p>
            <a>Coccidioidomycosis (Valley Fever) </a> is difficult to diagnosis due to lack of
            awareness and symptoms imitating many other respiratory infections.
          </p>{' '}
          <p>
            {' '}
            Valley Fever is said to cause nearly 30% of all community acquired pneumonia (CAP)
            cases, but only about 20,000 cases are reported each year in the US.<sup>1</sup> IMMY’s
            simple, rapid LFA allows for better care and stewardship for patients.
          </p>
          <p>
            <small>
              1) Valley Fever (Coccidiodomycoisis) Statistics. Updated 2022.{' '}
              <a
                className='text-break'
                target='_blank' rel="noreferrer"
                href='https://www.cdc.gov/fungal/diseases/coccidioidomycosis/statistics.html'
              >
                {' '}
                https://www.cdc.gov/fungal/diseases/coccidioidomycosis/statistics.html
              </a>
            </small>
          </p>
        </div>
      }
      CE_desc='FDA : Serum & CSF'
      image_1={clock_icon}
      image_1_desc1='Results in just'
      image_1_desc2='30 MINUTES'
      image_2={reduce_sendouts_icon}
      image_2_desc1='Reduce'
      image_2_desc2='SEND-OUT TESTING'
      image_3={run_all_shifts_icon}
      image_3_desc1='Run on '
      image_3_desc2='ALL SHIFTS'
      relevancy='CTA_Relevancy'
      CE_marked={false}
      documentCode='cta'
      ordering={[
        {
          name: '<i>Coccidioides</i> Antibody LFA',
          quantity: '50 tests',
          code: 'CTA2003',
        },
      ]}
      studies={[
        {
          link: '/study_documents/ID-Week-Poster.pdf',
          title:
            'Implementation of the sōna <i>Coccidioides</i> Antibody Lateral Flow Assay in the Clinical Laboratory Proves to Reduce Cost and Decrease Turnaround Time When Compared to Send out Immunodiffusion and Complement Fixation Testing  ',
          citation:
            'Candelaria WJ. Implementation of the sōna <i>Coccidioides</i> Antibody Lateral Flow Assay in the Clinical Laboratory Proves to Reduce Cost and Decrease Turnaround Time When Compared to Send out Immunodiffusion and Complement Fixation Testing.  Poster Presented at: IDWeek 2019; October 2019; Washington, DC.',
        },
        {
          link: '/study_documents/CTA2003_ICM2017_Poster.pdf',
          title:
            'Rapid Detection of <i>Anti-Coccidioides</i> Antibodies using the sōna <i>Coccidioides</i> Ab Lateral Flow Assay',
          citation:
            'Maddox S , Doherty B , Pelfrey J , Thompson G , Bauman S.  Rapid Detection of Anti-Coccidioides Antibodies Using the sōna <i>Coccidioides</i> Ab Lateral Flow Assay.  Poster presented at: 7th International Coccidioidomycosis Symposium; August 2017; Stanford, CA. ',
        },
        {
          link: '/study_documents/Cocci-poster-presentation_ASM2019.pdf',
          title:
            'Evaluation of Sōna Lateral Flow Assay for the Rapid Detection of <i>Coccidioides Immitis</i>',
          citation:
            'Contreras DA, Li Q, Garner OB. Evaluation of Sōna Lateral Flow Assay for the Rapid Detection of <i>Coccidioides Immitis</i>.  Poster presented at: ASM Microbe 2019; June 2019; San Francisco, CA.',
        },
        {
          title:
            'Comparative Study of Newer and Established Methods of Diagnosing Coccidioidal Meningitis',
          link: 'https://www.mdpi.com/2309-608X/6/3/125',
          citation:
            'Stevens DA, Pappagianis D, McGuire M, et al. Comparative Study of Newer and Established Methods of Diagnosing Coccidioidal Meningitis. J. Fungi. 2020;6(3):125.',
        },
      ]}
    />
  );
}

export function getHGM() {
  return (
    <Product
      title_1='clarus'
      title_2='HISTOPLASMA GM'
      title_2_color='#016836'
      title_3='ENZYME IMMUNOASSAY'
      etq={['PIS-00248','PIS-00156','PIS-00157']}
      product_image='/images/immy/updated_hgm.jpg'
      product_desc={
        <div>
          <p>
            The <i>Histoplasma</i> GM EIA offers an in-house assay to assist in reducing the number of
            misdiagnosed patients.
          </p>
          <p>
            {' '}
            This test is a commercially available diagnostic kit for the detection of{' '}
            <a>
              <i>Histoplasma</i>
            </a>{' '}
            antigen. In-house rapid testing is much more cost effective than send-out testing.
            <sup>1</sup>
          </p>
        </div>
      }
      CE_desc={
        <>
          : Urine <br />
          FDA : Urine
        </>
      }
      image_1={clock_icon}
      image_1_desc1='Results in'
      image_1_desc2='2 HOURS, 15 MINUTES'
      image_2={reduce_sendouts_icon}
      image_2_desc1='Reduce'
      image_2_desc2='SEND-OUT TESTING'
      image_3={save_money}
      image_3_desc1='96 Patients'
      image_3_desc2='PER PLATE'
      relevancy='Hgm_Relevancy'
      CE_marked={true}
      documentCode='hgm201'
      ordering={[
        {
          name: '<i>Histoplasma</i> Galactomannan EIA',
          quantity: '96 tests',
          code: 'HGM201',
        },
      ]}
      studies={[
        {
          link: 'https://jcm.asm.org/content/56/6/e01959-17',
          title:
            'Multicenter Validation of Commercial Antigenuria Reagents To Diagnose Progressive Disseminated Histoplasmosis in People Living with HIV/AIDS in Two Latin American Countries.',
          citation:
            'Cáceres DH, Samayoa BE, Medina NG, Tobón AM, Guzmán BJ, Mercado D, Restrepo A, Chiller T, Arathoon EE, Gómez BL.  Multicenter Validation of Commercial Antigenuria Reagents To Diagnose Progressive Disseminated Histoplasmosis in People Living with HIV/AIDS in Two Latin American Countries.  J Clin Microbiol. 2018 May 25;56(6).',
        },
        {
          link: 'https://academic.oup.com/ofid/article/6/4/ofz073/5319214',
          title:
            'Histoplasmosis, An Underdiagnosed Disease Affecting People Living With HIV/AIDS in Brazil: Results of a Multicenter Prospective Cohort Study Using Both Classical Mycology Tests and Histoplasma Urine Antigen Detection',
          citation:
            'Falci DR, Monteiro AA, Braz Caurio CF, et al. Histoplasmosis, An Underdiagnosed Disease Affecting People Living With HIV/AIDS in Brazil: Results of a Multicenter Prospective Cohort Study Using Both Classical Mycology Tests and Histoplasma Urine Antigen Detection. Open Forum Infect Dis. 2019;6(4).',
        },
      ]}
    />
  );
}

export function getCRY() {
  return (
    <Product
      title_1='clarus'
      title_2=''
      title_2_color='#c41230'
      title_3='CRYPTOCOCCAL ANTIGEN ENZYME IMMUNOASSAY'
      etq={['PIS-00111','PIS-00085','PIS-00193']}
      product_image='/images/immy/CRY101.jpg'
      product_desc={
        <div>
          <p>
            <a>Cryptococcal meningitis</a> is one of the most common opportunistic infections among
            HIV/AIDS patients, estimating more than 220,000 cases and 181,000 deaths worldwide each
            year.<sup>1</sup> Early detection and treatment of infection are key to reducing the
            mortality associated with this disease.
          </p>
          <p>
            {' '}
            This test is a direct immunoenzymatic, sandwich microplate assay and has been considered
            the most sensitive and specific EIA for the detection of <i>cryptococcal</i> antigen.
          </p>
          <p>
            <small>
              1) Rajasingham R, Smith RM, Park BJ, et al. Global burden of disease of HIV-associated
              cryptococcal meningitis: an updated analysis. Lancet Infect Dis. 2017;17(8):873-881.
            </small>
          </p>
        </div>
      }
      CE_desc={
        <>
          : Serum, CSF <br />
          FDA : Serum, CSF
        </>
      }
      image_1={clock_icon}
      image_1_desc1='Results in'
      image_1_desc2='2 HOURS'
      image_2={reduce_sendouts_icon}
      image_2_desc1='Reduce'
      image_2_desc2='SEND-OUT TESTING'
      image_3={accuracy_icon}
      image_3_desc1='Highly'
      image_3_desc2='SENSITIVE & SPECIFIC'
      relevancy='Cry_Relevancy'
      CE_marked={true}
      documentCode='cry101'
      ordering={[
        {
          name: '<i>Cryptococcus</i> Antigen EIA',
          quantity: '192 tests',
          code: 'CRY101',
        },
      ]}
      studies={[
        /*{
                              "title":"CDC. Fungal Diseases, Cryptococcosis-neoformans Statistics. NCEZID, DFWED. 2020.",
                                "link":"https://www.cdc.gov/fungal/diseases/coccidioidomycosis/statistics.html"
                        },*/
        {
          link: 'https://cvi.asm.org/content/20/1/52',
          title:
            'Large-scale evaluation of the immuno-mycologics lateral flow and enzyme-linked immunoassays for detection of cryptococcal antigen in serum and cerebrospinal fluid.',
          citation:
            'Hansen J, Slechta ES, Gates-Hollingsworth MA, Neary B, Barker AP, Bauman S, Kozel TR, Hanson KE.  Large-scale evaluation of the immuno-mycologics lateral flow and enzyme-linked immunoassays for detection of cryptococcal antigen in serum and cerebrospinal fluid. Clin Vaccine Immunol. 2013 Jan;20(1):52-5.',
        },
        {
          link: 'https://cvi.asm.org/content/19/12/1988',
          title: 'Comparison of Four Assays for the Detection of Cryptococcal Antigen',
          citation:
            'Binnicker MJ, Jespersen DJ, Bestrom JE, Rollins LO. Comparison of four assays for the detection of cryptococcal antigen. Clin Vaccine Immunol. 2012;19(12):1988–1990. ',
        },
      ]}
    />
  );
}

export function getCab() {
  return (
    <Product
      title_1='clarus'
      title_2='COCCIDIOIDES Ab'
      title_2_color='#c41230'
      title_3='ENZYME IMMUNOASSAY'
      etq={['PIS-00081', 'PIS-00189','PIS-00102']}
      product_image='/images/immy/CAB102_New_Packaging.jpg'
      product_desc={
        <div>
          <p>
            Coccidioidomycosis (Valley Fever) is difficult to diagnose due to lack of awareness and
            symptoms imitating many other respiratory infections. There were over 20,000 cases
            reported to the CDC in 2018. Valley Fever is said to cause nearly 30% of all community
            acquired pneumonia (CAP) cases in highly endemic areas.<sup>1</sup>
          </p>
          <p>
            This assay offers two 96-microwell plates and two different antigen preparations, which
            greatly reduces the potential for error and enhances the sensitivity and specificity
            compared to competitive assays.
          </p>
          <p>
            <small>
              1) Valley Fever (Coccidiodomycoisis) Statistics. Updated 2022.{' '}
              <a
                className='text-break'
                target='_blank' rel="noreferrer"
                href='https://www.cdc.gov/fungal/diseases/coccidioidomycosis/statistics.html'
              >
                {' '}
                https://www.cdc.gov/fungal/diseases/coccidioidomycosis/statistics.html
              </a>
            </small>
          </p>
        </div>
      }
      CE_desc='FDA : Serum & CSF'
      image_1={clock_icon}
      image_1_desc1='Results in'
      image_1_desc2='2 HOURS'
      image_2={accuracy_icon}
      image_2_desc1='Highly'
      image_2_desc2='SENSITIVE & SPECIFIC'
      image_3={
        <img
          src='/images/icons/well_plate.png'
          className='img-fluid'
          style={{ maxHeight: '64px' }}
        />
      }
      image_3_desc1='High Volume'
      image_3_desc2='96 WELL PLATES'
      relevancy='Cab_Relevancy'
      documentCode='cab102'
      ordering={[
        {
          name: '<i>Coccidioides</i> Antibody EIA',
          quantity: '96 tests',
          code: 'CAB102',
        },
      ]}
      studies={[
        {
          title:
            'Comparative Study of Newer and Established Methods of Diagnosing Coccidioidal Meningitis',
          link: 'https://www.mdpi.com/2309-608X/6/3/125',
          citation:
            'Stevens DA, Pappagianis D, McGuire M, et al. Comparative Study of Newer and Established Methods of Diagnosing Coccidioidal Meningitis. J. Fungi. 2020;6(3):125.',
        },
      ]}
    />
  );
}

export function getBta() {
  return (
    <Product
      title_1='clarus'
      title_2='BLASTOMYCES Ab'
      title_2_color='#c41230'
      title_3='ENZYME IMMUNOASSAY'
      etq={['PIS-00101', 'PIS-00140','PIS-00141']}
      product_image='/images/immy/updated_blasto.jpg'
      product_desc={
        <div>
          <p>
            This assay is a sensitive, specific, and rapid test, which can help consolidate the
            workload of immunodiffusion and complement fixation testing.
          </p>{' '}
          <p>
            It is the only commercially available diagnostic kit for the detection of{' '}
            <i>Blastomyces</i> antibody.
          </p>
        </div>
      }
      CE_desc='FDA : Serum'
      image_1={clock_icon}
      image_1_desc1='Results in'
      image_1_desc2='2 HOURS'
      image_2={accuracy_icon}
      image_2_desc1='Highly'
      image_2_desc2='SENSITIVE & SPECIFIC'
      image_3={
        <img
          src='/images/icons/well_plate.png'
          className='img-fluid'
          style={{ maxHeight: '64px' }}
        />
      }
      image_3_desc1='High Volume'
      image_3_desc2='96 WELL PLATE'
      relevancy='Bta_Relevancy'
      documentCode='blasto'
      ordering={[
        {
          name: '<i>Blastomyces</i> Total Antibody EIA',
          quantity: '96 tests',
          code: 'BTA101',
        },
      ]}
    />
  );
}

export function getAgm() {
  return (
    <Product
      title_1='clarus'
      title_2='ASPERGILLUS GM'
      title_2_color='#5f285e'
      title_3='ENZYME IMMUNOASSAY'
      etq={['PIS-00281','PIS-00260','PIS-00261']}
      product_image='/images/immy/AGM101_Packaging.jpg'
      product_desc={
        <p>
          The clarus Aspergillus Galactomannan EIA (AGM EIA) is an immunoenzymatic, sandwich
          microplate assay used for the qualitative detection of Aspergillus galactomannan in serum
          and bronchoalveolar lavage (BAL) samples from patients at-risk for invasive aspergillosis.
        </p>
      }
      CE_desc=': Serum & BAL'
      image_1={clock_icon}
      image_1_desc1='Results in'
      image_1_desc2='under 3 HOURS'
      image_2={accuracy_icon}
      image_2_desc1='Highly'
      image_2_desc2='SENSITIVE & SPECIFIC'
      image_3={
        <img
          src='/images/icons/well_plate.png'
          className='img-fluid'
          style={{ maxHeight: '64px' }}
        />
      }
      image_3_desc1='High Volume'
      image_3_desc2='96 WELL PLATE'
      relevancy='Agm_Relevancy'
      CE_marked={true}
      documentCode='agm101'
      ordering={[
        {
          name: '<i>Aspergillus</i> GM EIA',
          quantity: '96 tests',
          code: 'AGM101',
        },
      ]}
      studies={[]}
    />
  );
}

export function getLatex() {
  return (
    <Latex
      title_2='LATEX AGGLUTINATION'
      title_2_color='#1565C0'
      etq={['PIS-00082','PIS-00192','PIS-00136','PIS-00098','PIS-00106','PIS-00154','PIS-00155','PIS-00160','PIS-00161','PIS-00113','PIS-00174','PIS-00175']}
      product_image='/images/immy/latex_cropped.jpg'
      product_desc={
        <div>
          <p>
            The LA-Coccidioides (REF CL1001), LA-Histoplasma (REF HL1001), and LA-Sporothrix (REF SL
            1001) antibody detection systems are semi-quantitative tests that detect agglutinating
            antibodies against Coccidioides immitis (posadasii), Histoplasma capsulatum, or
            Sporothrix schenckii in the serum of patients with{' '}
            <a>coccidioidomycosis, histoplasmosis, or sporotrichosis</a>, respectively, to aid in
            the diagnosis of disease.
          </p>{' '}
          <h6 className='verlag-black text-right pt-2 pt-sm-2 pt-lg-4 pt-md-4 pt-xl-4'>
            <img className='img-fluid' style={{ maxWidth: '35px' }} src='/images/ce-mark.png\' />{' '}
            Serum
          </h6>
          <p>
            The LA-Cryptococcus (CR1003, CR1004) antigen detection systems are qualitative or
            semi-quantitative tests that detect antigens against Cryptococcus neoformans in serum
            and cerebrospinal fluid (CSF) as an aid in the diagnosis of Cryptococcosis.{' '}
          </p>
        </div>
      }
      CE_desc=': Serum FDA : Serum'
      relevancy=''
      CE_marked={true}
      noImages={true}
      documentCode='latex'
      ordering={[
        {
          name: '<i>Coccidiodes</i> Antibody Latex Agglutination Test System',
          quantity: '80 tests',
          code: 'CL1001',
        },
        {
          name: '<i>Histoplasma</i> Antibody Latex Agglutination Test System',
          quantity: '80 tests',
          code: 'HL1001',
        },
      ]}
    />
  );
}

export function getCF() {
  return (
    <Product
      title_2='COMPLEMENT FIXATION ANTIGENS/CONTROLS'
      title_2_color='#1565C0'
      etq={['PIS-00078','PIS-00190','PIS-00103','PIS-00079','PIS-00191','PIS-00220']}
      product_image='/images/immy/cf_plate.jpg'
      product_desc={
        <div>
          <p>
            Fungal antigens and positive controls are used to detect antibodies in patient serum by
            the complement fixation (CF) procedure.{' '}
          </p>
          <p>
            These reagents can be used to aid in the diagnosis of four specific fungal diseases:
            Histoplasmosis, blastomycosis, coccidioidomycosis, and aspergillosis.
          </p>
        </div>
      }
      CE_desc={
        <>
          : Serum <br />
          FDA : Serum
        </>
      }
      relevancy=''
      CE_marked={true}
      noImages={true}
      documentCode='cfr'
      ordering={[
        {
          name: '<i>Aspergillus</i> CF Antigen Dilute',
          quantity: '5 ml',
          code: 'A10150',
        },
        {
          name: '<i>Aspergillus</i> CF Positive Control',
          quantity: '1 ml',
          code: 'A20110',
        },
        {
          name: '<i>Blastomyces</i> CF Antigen Dilute',
          quantity: '5 ml',
          code: 'B10150',
        },
        {
          name: '<i>Blastomyces</i> CF Positive Control',
          quantity: '1 ml',
          code: 'B20110',
        },
        {
          name: '<i>Coccidioides</i> CF Antigen Dilute',
          quantity: '5 ml',
          code: 'C10150',
        },
        {
          name: '<i>Coccidioides</i> CF Positive Control',
          quantity: '1 ml',
          code: 'C20110',
        },
        {
          name: '<i>Histoplasma Mycelial</i> CF Antigen Dilute',
          quantity: '5 ml',
          code: 'H10150',
        },
        {
          name: '<i>Histoplasma Mycelial</i> CF Positive Control',
          quantity: '1 ml',
          code: 'H20110',
        },
        {
          name: '<i>Histoplasma</i> Yeast CF Antigen Dilute',
          quantity: '5 ml',
          code: 'H30150',
        },
        {
          name: '<i>Histoplasma</i> Yeast CF Positive Control',
          quantity: '1 ml',
          code: 'H40110',
        },
      ]}
    />
  );
}
